import type { ComputedRef, Ref } from 'vue';
import type { RequestParameters, RequestReturnType, Schemas } from '#shopware';
import type { Optional } from '~/types/shopware-api/helpers';
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import type { ProductCsvExtended } from '~/types/models/product/productFastOrder';

export type UseWishlistsReturn = {
    wishlists: Ref<Schemas['AhWishlist'][]>;
    wishlistWithDefaultFlag: ComputedRef<Schemas['AhWishlist'] | undefined>;
    selectedWishlistId: Ref<string>;
    selectedWishlist: ComputedRef<Schemas['AhWishlist'] | undefined>;
    selectedWishlistProducts: ComputedRef<Schemas['AhWishlistProduct'][]>;
    selectedWishlistCheckedProducts: ComputedRef<Partial<ProductCsvExtended>[]>;
    searchWishlists: () => Promise<Schemas['AhWishlist'][]>;
    updateWishlist: (updatedWishlist: RequestParameters<'updateWishlist'>) => Promise<void>;
    deleteWishlist: (wishlistId: string) => Promise<void>;
    addWishlist: (addDataWishlist: RequestParameters<'addWishlist'>) => Promise<void>;
    addProductToWishlist: (
        wishlistId: string,
        addDataProduct: Omit<RequestParameters<'addWishlistProduct'>, 'wishlistId'>,
    ) => Promise<void>;
    updateProductFromWishlist: (wishlistProductUpdateData: RequestParameters<'updateWishlistProduct'>) => Promise<void>;
    deleteProductFromWishlist: (wishlistId: string, productId: string) => Promise<void>;
    loaded: Ref<boolean>;
    checkedProductIds: Ref<Set<string>>;
};

export function useWishlists(appCall?: boolean): UseWishlistsReturn {
    const { apiClient } = useShopwareContext();
    const { getProducts } = useAlgolia();
    const { trackAlgoliaObjectConversion, currentPageQueryId } = useAlgoliaTracking();

    const wishlists: Ref<Schemas['AhWishlist'][]> = useState('wishlists', () => ref([]));
    const wishlistsLoaded = useState('wishlists-loaded', () => ref(false));
    const selectedWishlistId = useState('selected-wishlist-id', () => ref(''));
    const sharedProducts: Ref<AlgoliaProduct[]> = useState('selected-wishlist-products', () => ref([]));
    const isUpdating = ref(false);

    const wishlistWithDefaultFlag = computed(() => wishlists.value.find((wishlist) => wishlist.default ?? false));
    const selectedWishlist = computed<Schemas['AhWishlist'] | undefined>(() =>
        wishlists.value.find((wishlist) => {
            return selectedWishlistId.value ? wishlist.id === selectedWishlistId.value : (wishlist.default ?? false);
        }),
    );

    const selectedWishlistProducts = computed(() => {
        const wishlistProducts: Schemas['AhWishlistProduct'][] = [];

        if (!selectedWishlist.value?.productCollection || sharedProducts.value?.length === 0 || isUpdating.value)
            return wishlistProducts;

        selectedWishlist.value?.productCollection.forEach((currentProduct: Schemas['AhWishlistProduct']) => {
            const productData: (AlgoliaProduct & { unit?: Schemas['Unit'] }) | undefined = sharedProducts.value.find(
                (product) => product.productId === currentProduct.productId,
            );
            if (productData) {
                productData.unit = currentProduct.product?.unit ?? undefined;

                wishlistProducts.push({
                    ...currentProduct,
                    product: productData,
                });
            } else {
                wishlistProducts.push({
                    ...currentProduct,
                    product: {
                        ...currentProduct.product,
                        isIncomplete: true,
                    },
                });
            }
        });

        return wishlistProducts;
    });

    const checkedProductIds = ref<Set<string>>(new Set());

    const selectedWishlistCheckedProducts = computed(() =>
        selectedWishlistProducts.value
            .filter((item) => item.productId && checkedProductIds.value.has(item.productId))
            .map((product) => ({
                id: product.productId,
                productNumber: product.product?.productNumber,
                quantity: product.quantity,
                quantityValid: true,
                productNumberValid: true,
            })),
    );

    // register watchers only once on app call
    if (appCall) {
        watch(selectedWishlist, async (wishlist) => {
            checkedProductIds.value.clear();
            await _loadProductsByItemIds(wishlist?.productCollection);
        });

        // update selected wishlist if wishlists change
        watch(wishlists, () => {
            if (wishlists.value.length === 0 || selectedWishlistId.value) return;

            const defaultList = wishlists.value.find((item) => item.default ?? false);

            if (defaultList?.name) {
                selectedWishlistId.value = defaultList.id as string;
            } else {
                selectedWishlistId.value = wishlists.value[0].id as string;
            }
        });
    }

    const _loadProductsByItemIds = async (
        wishlistProducts: Schemas['AhWishlistProduct'][] | undefined,
    ): Promise<void> => {
        isUpdating.value = true;
        const productIds: string[] = [];

        wishlistProducts?.forEach((wishlistProduct) => {
            if (wishlistProduct.productId) productIds.push(wishlistProduct.productId);
        });

        if (productIds.length === 0) return;

        sharedProducts.value = await getProducts(
            productIds,
            { archive: true, sparePart: true, bWare: true },
            productIds.length,
        );
        isUpdating.value = false;
    };

    const searchWishlists = async (): Promise<RequestReturnType<'searchWishlists'>> => {
        try {
            const searchResponse = await apiClient.invoke('searchWishlists post /wishlist/search', {
                associations: {
                    productCollection: {
                        associations: {
                            product: {
                                associations: {
                                    unit: {},
                                },
                            },
                        },
                    },
                },
            });
            wishlists.value = searchResponse || [];
        } catch (error) {
            Logger.captureException(error);
        } finally {
            wishlistsLoaded.value = true;
        }

        return wishlists.value;
    };

    const addWishlist = async (addDataWishlist: RequestParameters<'addWishlist'>): Promise<void> => {
        try {
            await Promise.all([
                _removeDefaultFlagFromOtherWishlists(addDataWishlist),
                apiClient.invoke('addWishlist post /wishlist/add', addDataWishlist),
            ]);

            const wishlists = await searchWishlists();

            if (addDataWishlist?.default) {
                const defaultWishlist = wishlists.find((wishlist) => wishlist.default ?? false);

                if (defaultWishlist?.id) {
                    selectedWishlistId.value = defaultWishlist.id;
                }
            }
        } catch (error) {
            Logger.captureException(error);
        }
    };

    const updateWishlist = async (
        updatedWishlistData: Optional<RequestParameters<'updateWishlist'>, 'wishlistId'>,
    ): Promise<void> => {
        try {
            if (!updatedWishlistData.id) {
                return;
            }

            await Promise.all([
                _removeDefaultFlagFromOtherWishlists(updatedWishlistData),
                apiClient.invoke('updateWishlist patch /wishlist/{wishlistId}/update', {
                    ...updatedWishlistData,
                    wishlistId: updatedWishlistData.wishlistId || updatedWishlistData.id,
                }),
            ]);
            await searchWishlists();
        } catch (error) {
            Logger.captureException(error);
        }
    };

    // if default flag is set, remove it from other wishlists
    const _removeDefaultFlagFromOtherWishlists = async (
        updatedWishlist: Omit<RequestParameters<'updateWishlist'>, 'wishlistId' | 'id'>,
    ): Promise<void> => {
        if (updatedWishlist?.default && wishlistWithDefaultFlag.value) {
            await updateWishlist({
                wishlistId: wishlistWithDefaultFlag.value.id,
                id: wishlistWithDefaultFlag.value.id ?? '',
                default: false,
            });
        }
    };

    const deleteWishlist = async (wishlistId: string): Promise<void> => {
        try {
            selectedWishlistId.value = '';

            await apiClient.invoke('deleteWishlist delete /wishlist/delete/{wishlistId}', {
                wishlistId,
            });

            await searchWishlists();
        } catch (error) {
            Logger.captureException(error);
        }
    };

    const addProductToWishlist = async (
        wishlistId: string,
        addDataProduct: Omit<RequestParameters<'addWishlistProduct'>, 'wishlistId'>,
    ): Promise<void> => {
        try {
            await apiClient.invoke('addWishlistProduct post /wishlist/{wishlistId}/product/add', {
                ...addDataProduct,
                wishlistId,
            });

            trackAlgoliaObjectConversion({
                objectIDs: [addDataProduct.productId],
                queryID: currentPageQueryId.value,
            });
        } catch (error) {
            Logger.captureException(error);
        }
    };

    const updateProductFromWishlist = async (
        wishlistProductUpdateData: RequestParameters<'updateWishlistProduct'>,
    ): Promise<void> => {
        try {
            await apiClient.invoke(
                'updateWishlistProduct patch /wishlist/{wishlistId}/product/{wishlistProductId}/update',
                {
                    ...wishlistProductUpdateData,
                },
            );

            await searchWishlists();
        } catch (error) {
            Logger.captureException(error);
        }
    };

    const deleteProductFromWishlist = async (wishlistId: string, wishlistProductId: string): Promise<void> => {
        try {
            await apiClient.invoke(
                'deleteWishlistProduct delete /wishlist/{wishlistId}/product/delete/{wishlistProductId}',
                {
                    wishlistId,
                    wishlistProductId,
                },
            );

            await searchWishlists();
        } catch (error) {
            Logger.captureException(error);
        }
    };

    return {
        wishlists,
        wishlistWithDefaultFlag,
        selectedWishlistId,
        selectedWishlist,
        selectedWishlistProducts,
        selectedWishlistCheckedProducts,
        checkedProductIds,
        searchWishlists,
        updateWishlist,
        deleteWishlist,
        addWishlist,
        addProductToWishlist,
        updateProductFromWishlist,
        deleteProductFromWishlist,
        loaded: wishlistsLoaded,
    };
}
